<template>
  <div class="container">
    <h2>Add A Promotions</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">Cancel</button>
    <div class="row">
      <div class="col-md-4">
        <label class for="VehicleId">VehicleId</label>
        <input
          class="form-control"
          id="VehicleId"
          name="VehicleId"
          v-model="newPromotions.VehicleId"
          placeholder="VehicleId"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="newPromotions.Heading"
          placeholder="Heading"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="Copy">Copy</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="newPromotions.Copy"
          placeholder="Copy"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-2">
        <label class for="Image">Main Image</label>
        <div v-if="!editingMainImage">
          <img v-bind:src="getMainImageSource(newPromotions)" class="previewImage" />
          <button @click="editMainImage()" type="button" class="btn btn-success">Update Image</button>
        </div>
        <div v-if="editingMainImage">
          <button @click="updateMainImage()" type="button" class="btn btn-success">Update</button>
          <button @click="editMainImage()" type="button" class="btn btn-warning">Cancel</button>
          <FileUploader @ImageDataChanged="updateMainImageData" @ImageURLDataChanged="updateMainImageUrl" />
        </div>
      </div>
      <div class="col-md-2">
        <label class for="Image">Home Image</label>
        <div v-if="!editingHomeImage">
          <img v-bind:src="getHomeImageSource(newPromotions)" class="previewImage" />
          <button @click="editHomeImage()" type="button" class="btn btn-success">Update Image</button>
        </div>
        <div v-if="editingHomeImage">
          <button @click="updateHomeImage()" type="button" class="btn btn-success">Update</button>
          <button @click="editHomeImage()" type="button" class="btn btn-warning">Cancel</button>
          <FileUploader @ImageDataChanged="updateHomeImageData" @ImageURLDataChanged="updateHomeImageUrl" />
        </div>
      </div>
      <div class="col-md-4">
        <label class for="ImageMeta">ImageMeta</label>
        <input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="newPromotions.ImageMeta"
          placeholder="ImageMeta"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="DisplayOrder">DisplayOrder</label>
        <input
          class="form-control"
          id="DisplayOrder"
          name="DisplayOrder"
          v-model="newPromotions.DisplayOrder"
          placeholder="DisplayOrder"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="Link">Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="newPromotions.Link"
          placeholder="Link"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="LinkFriendly">LinkFriendly</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="newPromotions.LinkFriendly"
          placeholder="LinkFriendly"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="CashPrice">CashPrice</label>
        <input
          class="form-control"
          id="CashPrice"
          name="CashPrice"
          v-model="newPromotions.CashPrice"
          placeholder="CashPrice"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="TermsInMonths">TermsInMonths</label>
        <input
          class="form-control"
          id="TermsInMonths"
          name="TermsInMonths"
          v-model="newPromotions.TermsInMonths"
          placeholder="TermsInMonths"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="Desposit">Desposit</label>
        <input
          class="form-control"
          id="Desposit"
          name="Desposit"
          v-model="newPromotions.Desposit"
          placeholder="Desposit"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="Balloon">Balloon</label>
        <input
          class="form-control"
          id="Balloon"
          name="Balloon"
          v-model="newPromotions.Balloon"
          placeholder="Balloon"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="RateToClient">RateToClient</label>
        <input
          class="form-control"
          id="RateToClient"
          name="RateToClient"
          v-model="newPromotions.RateToClient"
          placeholder="RateToClient"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="TotalCostOfCredit">TotalCostOfCredit</label>
        <input
          class="form-control"
          id="TotalCostOfCredit"
          name="TotalCostOfCredit"
          v-model="newPromotions.TotalCostOfCredit"
          placeholder="TotalCostOfCredit"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="StartingFrom">StartingFrom</label>
        <input
          class="form-control"
          id="StartingFrom"
          name="StartingFrom"
          v-model="newPromotions.StartingFrom"
          placeholder="StartingFrom"
          tabindex="0"
          type="text"
          value
        />
          <label class for="StartingFromNamibia">StartingFromNamibia</label>
            <input
          class="form-control"
          id="StartingFromNamibia"
          name="StartingFromNamibia"
          v-model="newPromotions.StartingFromNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
          <label class for="StartingFromBotswana">StartingFromBotswana</label>
            <input
          class="form-control"
          id="StartingFromBotswana"
          name="StartingFromBotswana"
          v-model="newPromotions.StartingFromBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
          <label class for="StartingFrom">StartingFromSwaziland</label>
              <input
          class="form-control"
          id="StartingFromSwaziland"
          name="StartingFromSwaziland"
          v-model="newPromotions.StartingFromSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
        <div class>
          <small></small>
        </div>
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="newPromotions.LastUpdated"
          placeholder="LastUpdated"
          tabindex="0"
          type="text"
          value
        />
        <div class>
          <small></small>
        </div>
      </div>
      <br />
    </div>
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">Cancel</button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });
export default {
  name: "addPromotions",
  created: function() {},
  data() {
    return {
      newPromotions: {},
      uploadedMainImageData: {},
      uploadedMainImageUrl: null,
      editingMainImage: false,
      uploadedHomeImageData: {},
      uploadedHomeImageUrl: null,
      editingHomeImage: false
    };
  },
  components: {
    FileUploader
  },
  methods: {
    toggleView: function() {
      this.$emit("closeAddPromotions");
    },

    updateMainImageData: function(value) {
      this.uploadedMainImageData = value;
    },
    updateMainImageUrl: function(value) {
      this.uploadedMainImageUrl = value;
    },
    editMainImage: function() {
      this.editingMainImage = !this.editingMainImage;
    },
    updateMainImage: function() {
      this.editingMainImage = !this.editingMainImage;
    },
    getMainImageSource(data) {
      if (this.uploadedMainImageUrl) {
        return this.uploadedMainImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },

    updateHomeImageData: function(value) {
      this.uploadedHomeImageData = value;
    },
    updateHomeImageUrl: function(value) {
      this.uploadedHomeImageUrl = value;
    },
    editHomeImage: function() {
      this.editingHomeImage = !this.editingHomeImage;
    },
    updateHomeImage: function() {
      this.editingHomeImage = !this.editingHomeImage;
    },
    getHomeImageSource(data) {
      if (this.uploadedHomeImageUrl) {
        return this.uploadedHomeImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.HomeImage)) {
        if (data.HomeImage) {
          return this.$store.state.cdnUrl + data.HomeImage;
        } else {
          return data.HomeImage;
        }
      }
      return "";
    },


    isNull: function(obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    save: function() {
      var payload = {
        data: this.newPromotions,
        mainImage: this.uploadedMainImageData,
        homeImage: this.uploadedHomeImageData,
        success: response => {
          this.$emit("addPromotionsSuccess");
        },
        error: error => {
          miniToastr["error"](error, "Error", 1000, null);
        }
      };
      this.$store.dispatch("addPromotions", payload);
    }
  }
};
</script>
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
