<template>
  <div class="container">
    <h2>{{ editMode ? "Edit" : "Add" }} Promotions</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button @click="deletePromotions()" type="button" class="btn btn-danger">
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="VehicleId">VehicleId</label>
        <input
          class="form-control"
          id="VehicleId"
          name="VehicleId"
          v-model="editingPromotions.VehicleId"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingPromotions.Heading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Copy">Copy</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingPromotions.Copy"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class for="Image">Main Image</label>

        <ImageUploader
          title="Image"
           v-model="editingPromotions.Image"
          
        />
      </div>
      <div class="col-md-6">
        <label class for="Image">Home Page Image</label>

        <ImageUploader
          title="Image"
           v-model="editingPromotions.HomeImage"
        
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <br />
        <label class for="Image">Mobile Main Image</label>

        <ImageUploader
          title="Image"
           v-model="editingPromotions.ImageMobile"
       
        />
      </div>
      <div class="col-md-6">
        <br />
        <label class for="Image">Mobile Home Page Image</label>

        <ImageUploader
          title="Image"
           v-model="editingPromotions.HomeImageMobile"
          
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class for="ImageMeta">ImageMeta</label>
        <input
          class="form-control"
          id="ImageMeta"
          name="ImageMeta"
          v-model="editingPromotions.ImageMeta"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="DisplayOrder">DisplayOrder</label>
        <input
          class="form-control"
          id="DisplayOrder"
          name="DisplayOrder"
          v-model="editingPromotions.DisplayOrder"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Link">Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingPromotions.Link"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class for="LinkFriendly">LinkFriendly</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingPromotions.LinkFriendly"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="CashPrice">CashPrice</label>
        <input
          class="form-control"
          id="CashPrice"
          name="CashPrice"
          v-model="editingPromotions.CashPrice"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="TermsInMonths">TermsInMonths</label>
        <input
          class="form-control"
          id="TermsInMonths"
          name="TermsInMonths"
          v-model="editingPromotions.TermsInMonths"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class for="Desposit">Desposit</label>
        <input
          class="form-control"
          id="Desposit"
          name="Desposit"
          v-model="editingPromotions.Desposit"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="Balloon">Balloon</label>
        <input
          class="form-control"
          id="Balloon"
          name="Balloon"
          v-model="editingPromotions.Balloon"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="RateToClient">RateToClient</label>
        <input
          class="form-control"
          id="RateToClient"
          name="RateToClient"
          v-model="editingPromotions.RateToClient"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class for="TotalCostOfCredit">TotalCostOfCredit</label>
        <input
          class="form-control"
          id="TotalCostOfCredit"
          name="TotalCostOfCredit"
          v-model="editingPromotions.TotalCostOfCredit"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="StartingFrom">StartingFrom</label>
        <input
          class="form-control"
          id="StartingFrom"
          name="StartingFrom"
          v-model="editingPromotions.StartingFrom"
          placeholder=" "
          tabindex="0"
          type="text"
        />
          <label class for="StartingFromNamibia">StartingFromNamibia</label>
            <input
          class="form-control"
          id="StartingFromNamibia"
          name="StartingFromNamibia"
          v-model="editingPromotions.StartingFromNamibia"
          placeholder="Namibia "
          tabindex="0"
          type="text"
        />
          <label class for="StartingFromBotswana">StartingFromBotswana</label>
            <input
          class="form-control"
          id="StartingFromBotswana"
          name="StartingFromBotswana"
          v-model="editingPromotions.StartingFromBotswana"
          placeholder="Botswana"
          tabindex="0"
          type="text"
        />
          <label class for="StartingFromSwaziland">StartingFromSwaziland</label>
              <input
          class="form-control"
          id="StartingFromSwaziland"
          name="StartingFromSwaziland"
          v-model="editingPromotions.StartingFromSwaziland"
          placeholder="Swaziland "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingPromotions.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>

    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
  </div>
</template>
<script>
import _ from "lodash";
import FileUploader from "@/components/custom/FileUploader.vue";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditPromotions",

  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedMainImageData: {},
      uploadedMainImageUrl: null,
      uploadedHomeImageData: {},
      uploadedHomeImageUrl: null,
      editingMainImage: false,
      editingHomeImage: false,
    };
  },
  components: {
    FileUploader,
    ImageUploader,
  },
  created() {
    this.originalData = JSON.parse(JSON.stringify(this.editingPromotions));
    this.ImageMainBytes = this.editingPromotions.Image;
    this.ImageHomeBytes = this.editingPromotions.HomeImage;
  },

  props: ["editingPromotions", "editMode"],

  methods: {
    toggleView: function () {
      this.uploadedMainImageUrl = null;
      this.uploadedHomeImageUrl = null;
      this.$emit("closeEditPromotions");
    },

    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingPromotions));
      var payload = {
        data: data,
        mainImage: this.uploadedMainImageUrl,
        homeImage: this.uploadedHomeImageUrl,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editPromotionsSuccess");
            this.$emit("closeEditPromotions");
          } else {
            this.$emit("addPromotionsSuccess");
            this.$emit("closeEditPromotions");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      if (this.editMode) {
        this.$store.dispatch("editPromotions", payload);
      } else {
        this.$store.dispatch("addPromotions", payload);
      }
    },
    updateMainImageData: function (value) {
      this.uploadedMainImageData = value;
    },
    updateMainImageUrl: function (value) {
      this.editingPromotions.Image = value;
      this.uploadedMainImageUrl = value;
    },
    updateMainImageMobileUrl: function (value) {
      this.editingPromotions.ImageMobile = value;
    },
        updateHomeImageMobileUrl: function (value) {
      this.editingPromotions.HomeImageMobile = value;
    },
    editMainImage: function () {
      this.editingMainImage = !this.editingMainImage;
    },
    updateMainImage: function () {
      this.editingMainImage = !this.editingMainImage;
    },
    getMainImageSource(data) {
      if (this.uploadedMainImageUrl) {
        return this.uploadedMainImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        if (data.Image) {
          return this.$store.state.cdnUrl + data.Image;
        } else {
          return data.Image;
        }
      }
      return "";
    },
    updateHomeImageData: function (value) {
      this.uploadedHomeImageData = value;
    },
    updateHomeImageUrl: function (value) {
      this.editingPromotions.HomeImage = value;
      this.uploadedHomeImageUrl = value;
    },
    editHomeImage: function () {
      this.editingHomeImage = !this.editingHomeImage;
    },
    updateImage: function () {
      this.editingHomeImage = !this.editingHomeImage;
    },
    getHomeImageSource(data) {
      if (this.uploadedHomeImageUrl) {
        return this.uploadedHomeImageUrl;
      }
      if (!this.isNull(data) && !this.isNull(data.HomeImage)) {
        if (data.HomeImage) {
          return this.$store.state.cdnUrl + data.HomeImage;
        } else {
          return data.HomeImage;
        }
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    deletePromotions: function () {
      var payload = {
        data: this.editingPromotions,
        success: (response) => {
          this.$emit("editPromotionsSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deletePromotions", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
