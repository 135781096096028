import { render, staticRenderFns } from "./editPromotions2.vue?vue&type=template&id=49dbf586&scoped=true"
import script from "./editPromotions2.vue?vue&type=script&lang=js"
export * from "./editPromotions2.vue?vue&type=script&lang=js"
import style0 from "./editPromotions2.vue?vue&type=style&index=0&id=49dbf586&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49dbf586",
  null
  
)

export default component.exports